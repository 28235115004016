// utils/AxiosConfig.js
import axios from 'axios';

// Define la URL base dependiendo del entorno
const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://api-portafolio.francolemon.duckdns.org'  // URL de producción
    : 'http://localhost:3000';              // URL de desarrollo

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

console.log('Entorno actual:', process.env.NODE_ENV);

export default axiosInstance;